import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import MainPage from './pages/MainPage';
import Ad from './components/Ad';
import './index.css'

export default class App extends React.Component {

    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="has-navbar-fixed-top">
                <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
                    <div className="navbar-brand">
                        <a className="navbar-item" href="/"><h1 id="logo">STATBOX</h1></a>

                        <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>
                    <div className="navbar-menu">
                        {/* <div className="navbar-end">
                            <a className="navbar-item">About</a>
                        </div> */}
                    </div>
                </nav>
                <Ad></Ad>
                <div id="content">
                    <MainPage></MainPage>
                </div>
                <div id="footer">
                </div>
            </div>
        );
    }
}




//* App With Tab-Pages
// export default class App extends React.Component {

//     pages: any[]

//     constructor(props) {
//         super(props)
//         this.pages = [
//             { title: "Population", path: "/", component: <PopulationPage /> },
//             { title: "Economics", path: "/economics", component: <EconomicsPage /> }
//         ]
//     }
//     render() {
//         return (
//             <div>
//                 <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
//                     <div className="navbar-brand">
//                         <a className="navbar-item" href="/"><h1 className="logo">Statbox</h1></a>

//                         <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false">
//                             <span aria-hidden="true"></span>
//                             <span aria-hidden="true"></span>
//                             <span aria-hidden="true"></span>
//                         </a>
//                     </div>
//                     <div className="navbar-menu">
//                         <div className="navbar-end">
//                             <a className="navbar-item">About</a>
//                         </div>
//                     </div>
//                 </nav>
//                 <div id="content">
//                 <BrowserRouter>
//                     <TabBar tabs={this.pages}></TabBar>
//                     <Routes>
//                         {this.pages.map(page => {
//                             return <Route path={page.path} element={page.component} />
//                         })}
//                     </Routes>
//                 </BrowserRouter>
//             </div>
//             </div>
//         );
//     }
// }