import * as React from 'react';

export default class Ad extends React.Component {

    state: any

    constructor(props) {
        super(props)
        this.state = { active: false }
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose() {
        this.setState(() => ({ active: false }))
    }
    
    render() {
        if (!this.state.active) {
            return;
        }
        return (
            <div className="notification advertisement">
                <button className="delete" onClick={() => this.handleClose()}></button>
                Lorem ipsum dolor sit amet, consectetur
                adipiscing elit lorem ipsum dolor. <strong>Pellentesque risus mi</strong>, tempus quis placerat ut, porta nec nulla. Vestibulum rhoncus ac ex sit amet fringilla. Nullam gravida purus diam, et dictum <a>felis venenatis</a> efficitur.
            </div>
        )
    }
}