import * as React from 'react';
import StatValue from '../components/StatValue';
import data from '../data.json';

export default class MainPage extends React.Component {

    sections: any[]

    constructor(props) {
        super(props);
        this.sections = [
            { title: "Population", data: data.populationData },
            { title: "Economics", data: data.economicsData },
            { title: "Internet", data: data.internetData },
            { title: "Energy", data: data.energyData }
        ]
    }

    render() {
        return (
            <div>
                {this.sections.map(section => {
                    return (
                        <div className="page-section">
                            <h1 className="section-header">{section.title.toUpperCase()}</h1>
                            <div className="stat-list"><StatsTable data={section.data}></StatsTable></div>
                        </div>
                    )
                })}
            </div>
        )
    }
}


//* StatsTable *//
interface StatsTableProps { data: any[] }
class StatsTable extends React.Component<StatsTableProps> {
    data: any[]
    state: any

    constructor(props) {
        super(props);
        this.data = props.data;
        this.state = { selectedIndex: -1 }
    }

    handleCardClick(card: any, stat: any, index: number) {
        if (this.state.selectedIndex === index) {
            this.setState(() => ({ selectedIndex: -1 }))
        } else {
            this.setState(() => ({ selectedIndex: index }))
        }
    }

    render() {
        return (
            <table className="table is-fullwidth">
            <tbody>
            {this.data.map((stat, index) => {
                return (
                    <tr>
                    <div className="card stat-card" onClick={e => this.handleCardClick(e.currentTarget, stat, index)}>
                        <div className="stat-card-content">
                            <div className="name">
                                <img className="icon" width="30px" height="30px" src={stat.icon}/>
                                <p>{stat.title}</p></div>
                                <StatValue stat={stat}></StatValue>
                            </div>
                        {/* <div style={{ display: index === this.state.selectedIndex ? 'block' : 'none'}} >
                            <LineChart stat={stat}></LineChart>
                        </div> */}
                    </div>
                    </tr>
                )
            })}
            </tbody>
            </table>
        )
    }
}

//* Country Section *//
// class CountryStats extends React.Component {
//     stats: any[]

//     constructor(props) {
//         super(props);
//         this.stats = data.countryStats;
//       }

//       render() {
//         return (
//             <div id="table">
// 			<table className="card table is-fullwidth">
// 				<thead>
// 					<tr>
// 						<th>Rank</th>
// 						<th>Country</th>
// 						<th>Rate</th>
// 						<th>Population</th>
// 					</tr>
// 				</thead>
// 				<tbody>
//                     {this.stats.map((stat, index) => {
//                         return (
//                         <tr>
//                             <th>{index + 1}</th>
//                             <td><div className="name"><img className="icon" width="20px" height="20px" src={stat.icon}/> {stat.country}</div></td>
//                             <td>{stat.growthRate}</td>
//                             <td><StatValue stat={stat}></StatValue></td>
//                         </tr>
//                         )
//                     })}
// 				</tbody>
// 			</table>
// 		    </div>
//         )
//       }
// }